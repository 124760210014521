<template>
  <div style="margin-top: 10px" id="reviewContentDiv">
    <table
      border="1"
      style="
        width: 100%;
        font-size: 14px;
        text-align: center;
        border-collapse: collapse !important;
        border: 1px solid #000;
      "
    >
      <tr>
        <td
          colspan="16"
          class="py-2"
          style="
            background-color: #bf0000;
            color: #fff;
            font-size: 18px;
            font-weight: bolder;
          "
        >
          <span
            >每日复盘<span>({{ displayContent.daily_date }})</span></span
          >
        </td>
      </tr>
      <tr>
        <td colspan="16" class="py-2" style="background-color: #fff2cc">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
            "
          >
            <span
              v-for="item in displayContent.market_data.data"
              style="margin-right: 15px"
            >
              <span style="font-weight: bolder">{{ item.name }}:</span>
              <span :style="$stockHelpers.getColor(item.chgRate)">{{
                $stockHelpers.formatValue(item.chgRate)
              }}</span>
            </span>

            <span style="margin-right: 15px">
              <span style="font-weight: bolder">上涨家数:下跌家数 = </span>
              <span style="color: #ff1d19">{{
                displayContent.market_data.upNumInBlock
              }}</span>
              :
              <span style="color: #007f00">{{
                displayContent.market_data.downNumInBlock
              }}</span>
            </span>
            <span style="margin-right: 15px">
              <span style="font-weight: bolder">涨停板:跌停板 = </span>
              <span style="color: #ff1d19">{{
                displayContent.market_data.upStopnum
              }}</span>
              :
              <span style="color: #007f00">{{
                displayContent.market_data.downStopnum
              }}</span>
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="16" style="text-align: start; padding: 10px">
          <p>
            <span style="font-size: 15px"
              ><u><strong>投资脉络</strong></u></span
            ><span style="font-size: 15px">：</span>
          </p>
          <p v-html="displayContent.invest_context"></p>
        </td>
      </tr>
      <tr>
        <td colspan="16" style="text-align: start; padding: 10px">
          <p>
            <span style="font-size: 15px"
              ><u><strong>市场点评</strong></u></span
            ><span style="font-size: 15px">：</span>
          </p>
          <p v-html="displayContent.market_review"></p>
        </td>
      </tr>
      <tr>
        <td colspan="16" style="text-align: start; padding: 10px">
          <!-- 题材涨跌幅 -->
          <div class="cncpt-chg">
            <div class="title">LG题材涨跌幅</div>
            <div class="chg-content">
              <div class="cncpt-up-list">
                <div
                  class="cncpt-up-item"
                  v-for="item in displayContent.cncpt_chg.up"
                  :key="item.cncptId"
                >
                  <div class="cncptName">{{ item.cncptName }}</div>
                  <div class="chgRate">{{ formatValue(item.chgRate) }}</div>
                </div>
              </div>
              <div class="line">......</div>
              <div class="cncpt-down-list">
                <div
                  class="cncpt-down-item"
                  v-for="item in displayContent.cncpt_chg.down"
                  :key="item.cncptId"
                >
                  <div class="cncptName">{{ item.cncptName }}</div>
                  <div class="chgRate">{{ formatValue(item.chgRate) }}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- 题材拥挤度 -->

          <!-- 申万二级涨跌幅 -->
          <div class="sw-chg">
            <div class="title">申万二级涨跌幅</div>
            <div class="chg-content">
              <div class="sw-up-list">
                <div
                  class="sw-up-item"
                  v-for="item in displayContent.sw_chg.up"
                  :key="item.code"
                >
                  <div class="name">{{ item.name }}</div>
                  <div class="chgRate">{{ formatValue(item.chgRate) }}</div>
                </div>
              </div>
              <div class="line">......</div>
              <div class="sw-down-list">
                <div
                  class="sw-down-item"
                  v-for="item in displayContent.sw_chg.down"
                  :key="item.code"
                >
                  <div class="name">{{ item.name }}</div>
                  <div class="chgRate">{{ formatValue(item.chgRate) }}</div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="16" style="text-align: start; padding: 10px">
          <p>
            <span style="font-size: 15px"
              ><u><strong>市场要闻</strong></u></span
            ><span style="font-size: 15px">：</span>
          </p>
          <p v-html="displayContent.market_news"></p>
        </td>
      </tr>
      <tr>
        <td colspan="16" style="text-align: start; padding: 10px">
          <p>
            <span style="font-size: 15px"
              ><u><strong>投资日历</strong></u></span
            ><span style="font-size: 15px">：</span>
          </p>
          <p v-html="displayContent.invest_calendar"></p>
        </td>
      </tr>
      <tr>
        <td colspan="16" style="text-align: start; padding: 10px">
          <!-- 回购增发 -->
          <div class="trade">
            <div class="title">回购增发</div>
            <div class="trade-content">
              <div class="sub-title">
                定增、回购、增持（更新时间{{
                  format(displayContent.update_time)
                }}）
              </div>
              <div class="trade-header">增持（当天公告）</div>
              <table>
                <thead>
                  <tr>
                    <th style="width: 100px">代码</th>
                    <th style="width: 100px">简称</th>
                    <th style="width: 100px">最新公告日期</th>
                    <th style="width: 100px">方案进度</th>
                    <th>股东身份类型</th>
                    <th>拟变动数量上限（股）</th>
                    <th>拟变动数量下限（股）</th>
                    <th>拟变动数量金额上限</th>
                    <th>拟变动数量金额下限</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in displayContent.daily_Incr_decr_notice.data"
                    :key="item.security_code"
                  >
                    <td style="width: 100px">{{ item.security_code }}</td>
                    <td style="width: 100px">{{ item.security_name }}</td>
                    <td>{{ item.latestAnnoDate }}</td>
                    <td>{{ item.progress }}</td>
                    <td></td>
                    <td>{{ formatInt(item.quotaNumMax) }}</td>
                    <td>{{ formatInt(item.quotaNumMin) }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <div class="trade-header">定增预案（近一周）</div>
              <table>
                <thead>
                  <tr>
                    <th style="width: 100px">代码</th>
                    <th style="width: 100px">简称</th>
                    <th style="width: 100px">最新公告日期</th>
                    <th style="width: 100px">方案进度</th>
                    <th style="width: 100px">增发价格</th>
                    <th style="width: 100px">折价率</th>
                    <th style="width: 100px">预计募集资金（亿元）</th>
                    <th style="text-align: left">定向增发目的</th>
                    <th style="text-align: left">发行对象</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in displayContent.weekly_private_placement.data"
                    :key="item.security_code"
                  >
                    <td style="width: 100px">{{ item.security_code }}</td>
                    <td style="width: 100px">{{ item.security_name }}</td>
                    <td style="width: 100px">{{ item.latestAnnoDate }}</td>
                    <td style="width: 100px">{{ item.progress }}</td>
                    <td style="width: 100px">{{ item.spoPrice }}</td>
                    <td style="width: 100px">{{ item.spoPrice }}</td>
                    <td style="width: 100px">
                      {{ formatNumber(item.expectRaisedFunds) }}
                    </td>
                    <td style="text-align: left">
                      {{ item.targetedIssuance }}
                    </td>
                    <td style="text-align: left">{{ item.issuedTo }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="trade-header">回购（当天公告）</div>
              <table>
                <thead>
                  <tr>
                    <th rowspan="2" style="width: 100px">代码</th>
                    <th rowspan="2" style="width: 100px">简称</th>
                    <th rowspan="2" style="width: 100px">最新公告日期</th>
                    <th rowspan="2" style="width: 100px">回购进度</th>
                    <th colspan="2">回购实施</th>
                    <th colspan="3">回购预案</th>
                  </tr>
                  <tr>
                    <th>回购均价</th>
                    <th>占公告回购总资金比例</th>
                    <th>公告回购金额上限（万元）</th>
                    <th>公告回购金额下限（万元）</th>
                    <th>占总股本比例（%）</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in displayContent.daily_repurchase.data"
                    :key="item.security_code"
                  >
                    <td style="width: 100px">{{ item.security_code }}</td>
                    <td style="width: 100px">{{ item.security_name }}</td>
                    <td style="width: 100px">{{ item.latestAnnoDate }}</td>
                    <td style="width: 100px">{{ item.progress }}</td>
                    <td></td>
                    <td></td>
                    <td>{{ formatWan(item.rpurAmountMax) }}</td>
                    <td>{{ formatWan(item.rpurAmountMin) }}</td>
                    <td>{{ item.rpurTotalRate }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="trade-header">定增上市（近1个月）</div>
              <table>
                <thead>
                  <tr>
                    <th style="width: 100px">代码</th>
                    <th style="width: 100px">简称</th>
                    <th style="width: 100px">发行日期</th>
                    <th style="width: 100px">定增股份上市日</th>
                    <th style="width: 100px">发行价格</th>
                    <th style="width: 100px">实际募集资金总额（亿元）</th>
                    <th style="width: 100px">是否大股东认购</th>
                    <th style="text-align: left">增发目的</th>
                    <th style="text-align: left">发行对象</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in displayContent.weekly_additional_Issuance
                      .data"
                    :key="item.security_code"
                  >
                    <td style="width: 100px">{{ item.security_code }}</td>
                    <td style="width: 100px">{{ item.security_name }}</td>
                    <td style="width: 100px">{{ item.latestAnnoDate }}</td>
                    <td></td>
                    <td>{{ item.issuePrice }}</td>
                    <td>{{ formatNumber(item.factRaisedFunds) }}</td>
                    <td></td>
                    <td style="text-align: left">
                      {{ item.targetedIssuance }}
                    </td>
                    <td style="text-align: left">{{ item.issuedTo }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="16" style="text-align: start; padding: 10px">
          <!-- 热门题材 -->
          <div class="hot-cncpt">
            <div class="hot-cncpt-header">
              <div class="title">热门题材</div>
              <!-- <div class="action" @click="showSelectDialog">题材编辑</div> -->
            </div>

            <div class="cncpt-content">
              <table>
                <thead>
                  <tr>
                    <th rowspan="2" colspan="2">热门题材</th>
                    <th rowspan="2">投资逻辑/信息更新</th>
                    <th colspan="4">重点个股/个股表现</th>
                  </tr>
                  <tr>
                    <th style="width: 100px">证券代码</th>
                    <th style="width: 100px">简称</th>
                    <th style="width: 100px">日涨幅</th>
                    <th style="width: 100px">近3日涨幅</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="item in displayContent.hot_cncpt.data">
                    <tr>
                      <td :rowspan="item.total" style="width: 100px">
                        {{ item.category }}
                      </td>
                      <td :rowspan="item.total" style="width: 100px">
                        {{ item.cncptName }}
                      </td>
                      <td :rowspan="item.total">
                        <template v-for="it in item.datas">
                          <div class="desc" v-if="it.category == 1">
                            {{ it.description }}
                          </div>
                          <div
                            class="desc"
                            v-if="it.category == 2"
                            @click="previewFile(it)"
                          >
                            {{ it.fileName }}
                          </div>
                        </template>
                      </td>
                      <td>
                        {{
                          item.components && item.components[0]
                            ? item.components[0].scrCode
                            : ""
                        }}
                      </td>
                      <td>
                        {{
                          item.components && item.components[0]
                            ? item.components[0].scrName
                            : ""
                        }}
                      </td>
                      <td>
                        {{
                          item.components && item.components[0]
                            ? formatValue(item.components[0].chgRate)
                            : ""
                        }}
                      </td>
                      <td>
                        {{
                          item.components && item.components[0]
                            ? formatValue(item.components[0].chgRateThree)
                            : ""
                        }}
                      </td>
                    </tr>
                    <template v-for="it in item.components.slice(1)">
                      <tr>
                        <td>{{ it.scrCode }}</td>
                        <td>{{ it.scrName }}</td>
                        <td
                          :class="{
                            redColor: it.chgRate > 0,
                            greenColor: it.chgRate < 0,
                          }"
                        >
                          {{ formatValue(it.chgRate) }}
                        </td>
                        <td
                          :class="{
                            redColor: it.chgRateThree > 0,
                            greenColor: it.chgRateThree < 0,
                          }"
                        >
                          {{ formatValue(it.chgRateThree) }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
      <!-- 
      <tr style="background-color: #bf0000; color: #fff; font-size: 15px">
        <td colspan="4" rowspan="2" style="width: 340px">热门赛道/标签名称</td>
        <td colspan="8" rowspan="2">投资逻辑/信息更新</td>
        <td colspan="4" style="width: 300px">重点个股/个股表现</td>
      </tr>
      <tr style="background-color: #bf0000; color: #fff; font-size: 15px">
        <td colspan="1" style="width: 100px">证券代码</td>
        <td colspan="1" style="width: 100px">简称</td>
        <td colspan="1" style="width: 100px">日涨幅</td>
        <td colspan="1" style="width: 100px">近3日涨幅</td>
      </tr> -->
      <!-- <template
        v-for="(tag, tagIndex) in displayContent.content.tableData.labels"
      >
        <template v-for="(tagChild, tagChildIndex) in tag.child">
          <template v-for="(stock, stockId) in tagChild.stocks">
            <tr>
              <td
                colspan="2"
                :rowspan="getFatherSpan(tag)"
                v-if="tagChildIndex == 0 && stockId == 0"
                style="width: 140px"
                :style="{
                  backgroundColor:
                    tag.name.indexOf('38, 38, 38') == -1
                      ? '#e3e3e3'
                      : '#d6e9f3',
                }"
                v-html="tag.name"
              ></td>
              <td
                colspan="2"
                :rowspan="tagChild.stocks.length"
                v-if="stockId == 0"
                style="width: 200px"
                :style="{
                  backgroundColor:
                    tag.name.indexOf('38, 38, 38') == -1
                      ? '#e3e3e3'
                      : '#d6e9f3',
                }"
                v-html="tagChild.name"
              ></td>
              <td
                colspan="8"
                :rowspan="tagChild.stocks.length"
                v-if="stockId == 0"
                v-html="tagChild.content"
                style="text-align: start; background-color: #fff2cc"
              ></td>

              <td
                style="font-weight: bolder; font-size: 12px"
                :style="{ color: stock.isHot ? '#bf0000' : '#2f75b5' }"
              >
                {{ stock.code }}
              </td>
              <td
                style="font-weight: bolder; font-size: 12px"
                :style="{ color: stock.isHot ? '#bf0000' : '#2f75b5' }"
              >
                {{ stock.name }}
              </td>
              <td
                style=""
                :style="{ backgroundColor: getStockStyle(stock.day_1) }"
              >
                {{ $stockHelpers.getPercent(stock.day_1) }}
              </td>
              <td
                style=""
                :style="{ backgroundColor: getStockStyle(stock.day_3) }"
              >
                {{ $stockHelpers.getPercent(stock.day_3) }}
              </td>
            </tr>
          </template>
        </template>
      </template> -->
    </table>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Review from "@/api/review.js";
import Print from "print-js";
export default {
  components: {},
  props: {
    displayContent: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    formatValue(value) {
      if (typeof value === "undefined") {
        return "";
      }
      value = this.formatPrefix(value);
      value = this.formatPercent(value);
      return value;
    },
    formatPrefix(value) {
      if (value == "--") {
        return value;
      }
      value = parseFloat(value).toFixed(2);
      value = value > 0 ? "+" + value : value;

      return value;
    },
    formatPercent(value) {
      if (value == "--") {
        return value;
      }
      value = value.toString();
      if (!value.includes("%")) {
        value = value + "%";
      }
      return value;
    },
    formatNumber(value) {
      if (value == null || value == "" || typeof value === "undefined") {
        return "";
      }
      const num = parseFloat(value);
      const result = (num / 100000000).toFixed(2) + "亿";
      return result;
    },
    formatWan(value) {
      if (value == null || value == "" || typeof value === "undefined") {
        return "";
      }
      const num = parseFloat(value);
      const result = (num / 10000).toFixed(2) + "万";
      return result;
    },
    formatInt(value) {
      if (value == null || value == "" || typeof value === "undefined") {
        return "";
      }
      const num = parseInt(value);
      return num;
    },
    format(val) {
      return this.timetrans(val);
    },
    getFatherSpan(item) {
      let span = 0;
      item.child.forEach((item) => {
        span += item.stocks.length;
      });
      return span;
    },
    getStockStyle(value) {
      if (value > 0) {
        let opacity = 1 - Math.abs(0.1 - value) / 0.1;
        if (value > 0.1) {
          opacity = 1;
        }
        return `rgba(238, 138, 141, ${opacity})`;
      } else if (value < 0) {
        let opacity = Math.abs(0.1 - value) / 0.1 - 1;
        if (value < -0.1) {
          opacity = 1;
        }
        return `rgba(146, 221, 175, ${opacity})`;
      } else {
        return "#fff";
      }
    },
    print() {
      this.appStore.printTitle = "每日复盘" + this.displayContent.title;
      this.appStore.printContent =
        document.getElementById("reviewContentDiv").innerHTML;
      let routeUrl = this.$router.resolve({ name: "打印页面" });
      window.open(routeUrl.href, "_blank");
      // printJS({
      //   printable: reviewContentDiv,
      //   type: 'html',
      //   scanStyles: false,
      // });
    },
    timetrans(d, type, f) {
      if (typeof d === "string") {
        d = d.replace(/\-/g, "/");
      }
      let date = new Date(d);
      let Y = date.getFullYear();
      let M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let _M = date.getMonth() + 1;
      let D = !f && date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let w = date.getDay();
      let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      switch (type) {
        case "yyyy年MM月dd日 午HH:mm":
          h = h * 1;
          if (h < 12) {
            h = "上午" + (h < 10 ? "0" + h : h);
          } else {
            h = h - 12;
            h = "下午" + (h < 10 ? "0" + h : h);
          }
          return Y + "年" + M + "月" + D + "日" + "  " + h + ":" + m;
        case "yyyy年MM月dd日":
          return Y + "年" + _M + "月" + D + "日";
        case "yyyy年MM月dd日 星期":
          let arr = [
            "星期天",
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六",
          ];
          return Y + "年" + _M + "月" + D + "日" + "  " + arr[w];
        case "yyyy年MM月":
          return Y + "年" + _M + "月";
        case "yyyy-MM":
          return Y + "-" + _M;
        case "yyyy/MM/dd":
          return Y + "/" + M + "/" + D;
        case "yyyy-MM-dd":
          return Y + "-" + M + "-" + D;
        case "MM-dd":
          return M + "-" + D;
        case "HH:mm:SS":
          return h + ":" + m + ":" + s;
        case "HH:mm":
          return h + ":" + m;
        case "yyyy-MM-dd HH:mm":
          return Y + "-" + M + "-" + D + "  " + h + ":" + m;
        case "MM-dd HH:mm":
          return M + "-" + D + "  " + h + ":" + m;
        case "MM/dd HH:mm":
          return M + "/" + D + "  " + h + ":" + m;
        case "MM月dd日":
          return _M + "月" + D + "日";
        case "YYYY.MM.DD HH:mm":
          return Y + "." + M + "." + D + " " + h + ":" + m;
        case "YYYY.MM.DD":
          return Y + "." + M + "." + D;
        default:
          return Y + "-" + M + "-" + D + "  " + h + ":" + m + ":" + s;
      }
    },
    async previewFile(row) {
      // await this.getFile(row.id)
      //   .then((url) => {
      //     console.log(url);
      //     Bus.$emit("previewFile", { url: url, name: row.name });
      //   })
      //   .catch(() => {});
      console.log(row.storePath);
      console.log(row.storePath);
      let len = row.storePath.split(".").length;
      let nextText = row.storePath.split(".")[len - 1];
      console.log(nextText);
      Bus.$emit("previewFile", {
        url: row.storePath,
        name: row.fileName + "." + nextText,
      });
    },
  },
};
</script>
<style scope lang="scss">
.cncpt-chg {
  .title {
    padding: 10px 0;
  }
  .chg-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .cncpt-up-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    .cncpt-up-item {
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid transparent;
      color: #f04848;
      background: #fff1f1;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        border: 1px solid #f04848;
      }
      .cncptName {
        margin-bottom: 5px;
      }
      .chgRate {
        text-align: center;
      }
    }
  }
  .line {
    padding: 20px;
  }
  .cncpt-down-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    .cncpt-down-item {
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid transparent;
      color: #22a875;
      background: #ebfbf8;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        border: 1px solid #22a875;
      }
      .cncptName {
        margin-bottom: 5px;
      }
      .chgRate {
        text-align: center;
      }
    }
  }
}
.sw-chg {
  .title {
    padding: 10px 0;
  }
  .chg-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .sw-up-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    .sw-up-item {
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid transparent;
      color: #f04848;
      background: #fff1f1;
      border-radius: 8px;
      &:hover {
        border: 1px solid #f04848;
      }
      .name {
        margin-bottom: 5px;
      }
      .chgRate {
        text-align: center;
      }
    }
  }
  .line {
    padding: 20px;
  }
  .sw-down-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    .sw-down-item {
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid transparent;
      color: #22a875;
      background: #ebfbf8;
      border-radius: 8px;
      &:hover {
        border: 1px solid #22a875;
      }
      .name {
        margin-bottom: 5px;
      }
      .chgRate {
        text-align: center;
      }
    }
  }
}
.trade {
  .title {
    padding: 10px 0;
  }
  .trade-content {
    .sub-title {
      padding: 5px 0;
      color: #fff;
      // background-color: #eb5c20;
      background-color: #bf0000;
      text-align: center;
    }
    .trade-header {
      padding: 5px 0;
      color: #000;
      background-color: rgb(255, 242, 204);
      text-align: center;
    }
    table {
      width: 100%;

      border-collapse: collapse;
      border: none;
    }
    thead {
      color: #fff;
    }
    tr {
      &:hover {
        background-color: #fff8ee;
      }
    }
    th {
      padding: 5px 0;
      border: solid#000 1px;
      background-color: rgb(191, 0, 0);
    }
    td {
      padding: 5px 0;
      border: 1px solid #000;
      text-align: center;
    }
  }
}

.hot-cncpt {
  .hot-cncpt-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
  }
  .title {
    padding: 10px 0;
  }
  .action {
    color: #c82a29;
  }
  .cncpt-content {
  }
  table {
    width: 100%;

    border-collapse: collapse;
    border: none;
  }
  thead {
    color: #fff;
  }
  tr {
    &:hover {
      background-color: #fff8ee;
    }
  }
  th {
    padding: 5px 0;
    border: solid#000 1px;
    background-color: rgb(191, 0, 0);
  }
  td {
    padding: 5px 0;
    border: 1px solid #000;
    text-align: center;
  }
  .desc {
    text-align: left;
    padding: 10px 5px;
    cursor: pointer;
    &:hover {
      color: var(--g-text-light-highlight);
    }
  }
}
td p {
  margin: 15px 0;
}
.greenColor {
  color: #04b549 !important;
}
.redColor {
  color: #ea2222 !important;
}
</style>
